@charset "utf-8";

// Import a Google Font
// @import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

@import "../node_modules/bulma/bulma.sass";

// .page {
//   height: 100vh;
// }

.spinner {
  @include loader;
  width: 3rem;
  height: 3rem;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
}
// .h100 {
//   height: 100%;
// }
